import * as Highcharts from 'highcharts';
import { AppConstant } from 'src/app/constants/app.const';
import { Chart } from './chart';
import * as _ from 'lodash';
import { Injectable } from "@angular/core";

@Injectable()
export class ChartService {

    getChartOptions(chartType: string, chartOptions: Highcharts.Options, chartData: any): Highcharts.Options {

        switch (chartType) {

            case AppConstant.ChartType.Bar:
                return _.merge(chartOptions, this.getBarChartOptions(chartData));

            case AppConstant.ChartType.Column:
                return _.merge(chartOptions, this.getColumnChartOptions(chartData));

            case AppConstant.ChartType.Pie:
               // return _.merge(chartOptions, this.getPieChartOptions(chartData));
                return this.getPieChartOptions(chartData);

            default:
                alert('Incorrect chart type');
        }
    }

    private getBarChartOptions(chartData: Chart): Highcharts.Options {

        const self = this;

        return {
            chart: {
                type: AppConstant.ChartType.Bar,
                backgroundColor: '#ddd',
            },
            title: {
                text: chartData.title || null
            },
            xAxis: {
                type: 'category',
                categories: chartData.xAxisCategories,
                crosshair: true
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Observation (#)'
                }
            },
            colors: ['#42def4','#ffe292','#5aefa7','#f6d0cd','#4285F4', '#ffc728', '#13cc72','#e78178','#5842f4','#F4B400', 
            '#0F9D58','#DB4437','#07347e', '#c18e00', '#0b6e3e','#bd2e22','#031534','#a87c00','#085731','#a7291e'],
            credits: { enabled: false },
            exporting: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.y, 0);
                }
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0);
                        }
                    }
                },
                bar: {
                    pointWidth: 20
                }
            },
            series: chartData.series,
            drilldown: {
                drillUpButton: {
                    position: {
                        verticalAlign: 'top'
                    },
                    relativeTo: 'spacingBox'
                },
                series: []
            },
            lang: {
                drillUpText: 'Back To Observations'
            }
        };
    }

    private getColumnChartOptions(chartData: Chart): Highcharts.Options {

        const self = this;

        return {
            chart: {
                type: AppConstant.ChartType.Column,
                backgroundColor: '#ddd'
            },
            title: {
                text: chartData.title || null
            },
            colors: ['#42def4','#ffe292','#5aefa7','#f6d0cd','#4285F4', '#ffc728', '#13cc72','#e78178','#5842f4','#F4B400', 
            '#0F9D58','#DB4437','#07347e', '#c18e00', '#0b6e3e','#bd2e22','#031534','#a87c00','#085731','#a7291e'],
            credits: { enabled: false },
            exporting: { enabled: false },
            legend: { enabled: true },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.y, 0);
                }
            },
            yAxis: {
                min: 0,
                allowDecimals: false,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontSize: '11px',
                        color: '#444',
                        fontWeight: 'bold',
                        textOutline: '0px 0px'
                    }
                }
            },
            xAxis: {
                type: 'category',
                categories: chartData.xAxisCategories,
                crosshair: true,
                labels: {
                    enabled: true
                }
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0);
                        }
                    }
                },
                column: {
                    pointWidth: 20,
                    groupPadding: 0.5
                }
            },
            series: chartData.series,
            drilldown: {
                drillUpButton: {
                    position: {
                        verticalAlign: 'top'
                    },
                    relativeTo: 'spacingBox'
                },
                series: []
            },
            lang: {
                drillUpText: 'Back To Locations'
            }
        };
    }

     
    private getPieChartOptions(chartData: Chart): Highcharts.Options {

        const self = this;

        return {
            chart: {
                type: AppConstant.ChartType.Pie,
                backgroundColor: '#ddd'
            },
            title: {
                text: chartData.title || null
            },
            subtitle: {
                text: chartData.subTitle ? document.ontouchstart === undefined ?
                    'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                    : null
            },
            colors: ['#5842f4','#F4B400','#0F9D58','#DB4437'],
            credits: { enabled: false },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.name + '</b>: ' + Highcharts.numberFormat(this.y, 0);
                }
            },
            legend: {
                enabled: true
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    dataLabels: {
                        enabled: false,
                        align: 'right',
                        inside: true,
                        style: {
                            fontSize: '11px',
                            color: '#444',
                            fontWeight: 'normal',
                            textOutline: '0px 0px'
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0);
                        }
                    }
                },
                pie: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    shadow: false,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
           series: chartData.series/* [{
                size: '90%',
                innerSize: '55%',
                data: chartData.series[0].data
            }] */
        };
    }
}

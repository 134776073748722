import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '../logger/logger.service';
import { Router } from '@angular/router';
import { LoaderService } from '../loader/loader.service';
import { AlertMessageService } from '../../shared/services/alert-message.service';
import { UrlConstant } from 'src/app/constants/url.const';

@Injectable()
export class GlobalExceptionHandler implements ErrorHandler {

    constructor(private injector: Injector) {
    }

    handleError(error) {

        if (error && (error.message.indexOf('xAxis') > -1 || error.message.indexOf('is') > -1)) {
            return null;
        }

        const router = this.injector.get(Router);
        const loggerService = this.injector.get(LoggerService);
        const alertService = this.injector.get(AlertMessageService);
        const loaderService = this.injector.get(LoaderService);

        loaderService.showPageLoader(false);

        if (error instanceof HttpErrorResponse) {

            if (error.status === 401) { // Unauthorized

                router.navigate([UrlConstant.Unauthenticated]);

            } else if (error.status === 403) { // Forbidden

                router.navigate([UrlConstant.Unauthorized]);

            } else { // All the general type of server exception will be displayed here.

                router.navigate([UrlConstant.ErrorPage]);
            }
        }

        // alertService.error('Error Occured', error.message || error);
        loggerService.error(error);
    }
}

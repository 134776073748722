<div class="row">
    <div class="col-lg-12">
        <div class="message-area text-center">
            <h1>
                <i class="fa fa-ban"></i> {{ 'ErrorPage.NotAuthenticatedTitle' | translate}}
            </h1>

            <h3>
                Please <a class="text-primary" (click)="signIn()">sign in</a> before continue.
            </h3>

            <p>
                <i class="fa fa-spinner fa-spin"></i>{{'ErrorPage.NotAuthenticatedBody' | translate}}
            </p>

        </div>
    </div>
</div>
export const CoreConstants = {

    AccessToken: 'accessToken',
    LoggedInUserInfo: 'loggedInUserInfo',
    ContentTypes: {
        Json: 'application/json'
    },
    CompanyName: 'Safe School',
    CurrentLanguage: 'currentLanguage',
    CurrentLocale: 'currentLocale'
};

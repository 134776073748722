import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadEnd, RouteConfigLoadStart } from '@angular/router';
import { BaseComponent } from './shared/components/base.component';
import { Locale } from './core/localization/locale';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent extends BaseComponent implements OnInit {

  isLazyLoadModuleLoading: boolean;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.setDefaultLanguage();
  }

  setDefaultLanguage() {

    const supportedLanguages = this.localeService.getLanguagesByCodes(environment.supportedLanguages);

    let locale = this.localeService.getCurrentLocale();

    if (!locale && supportedLanguages != null && supportedLanguages.length > 0) {
      locale = new Locale();
      locale.language = supportedLanguages[0];
      this.localeService.setCurrentLocale(locale.language.languageCode);
    }

    this.setTranslationLocale();
  }

  navigationInterceptor(event: RouterEvent): void {

    if (event instanceof RouteConfigLoadStart) {
      this.isLazyLoadModuleLoading = true;
    }

    if (event instanceof RouteConfigLoadEnd) {
      this.isLazyLoadModuleLoading = false;
    }
  }

}

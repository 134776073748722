import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-widget-loader',
    templateUrl: './widget-loader.component.html',
    styleUrls: ['./widget-loader.component.scss']
})

export class WidgetLoaderComponent {

    @Input() showLoader: boolean;

    @Input() displayMessage: string;
}

import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupService} from './popup.service';
import { IFollowupType } from './popup.types';
import moment from 'moment';
import {ObservationType} from '../../../modules/observation-type/observation-type';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit, OnDestroy, AfterContentChecked {
    closeResult = '';
    followUpList: [];
    followupitem: [];
    @Input()
    observation_id: any;
    FollowupType: IFollowupType = {
        assigned_to: '', date_created: '', followup_item: '', id: '', observation_id: '',
        expected_date: null
    };
    editForm = false;
    viewForm = true;
    isDetailedView = false;
    addForm = false;
    minDate = moment(new Date()).format('YYYY-MM-DD');
    maxDate = '2100-12-31';
    @ViewChild('f')myform: NgForm;
    constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private popService: PopupService, private cdref: ChangeDetectorRef) {
        console.log('pop called');
    }

    open(content) {
        /*  this.modalService.open(content).result.then((res) => {
              this.closeResult = `Closed with: ${res}`;
          }, (res) => {
              this.closeResult = `Dismissed ${this.getDismissReason(res)}`;
          });
          this.popService.getfollowlist(this.observation_id).subscribe((resultValue: any) => {
              this.followUpList = resultValue.data[0];
          });*/

    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    ngOnDestroy() {
        console.log('destroyed');
        this.modalService.dismissAll();
    }

    save() {
        const data = {
            observation_id: this.observation_id,
            follow_up_item: this.FollowupType.followup_item,
            assigned_to: this.FollowupType.assigned_to,
            comments: this.FollowupType.comments,
            expected_date : this.FollowupType.expected_date
        };
        // console.log(data);
        this.popService.addfollowitem(data).subscribe((postResult: any) => {
            if (postResult.status === 'success') {
                this.addForm = false;
                this.viewForm = true;
                this.popService.getfollowlist(this.observation_id).subscribe((resultValue: any) => {
                    this.followUpList = resultValue.data;
                });
            }
        });
       /* this.FollowupType.followup_item = '';
        this.FollowupType.assigned_to = '';
        this.FollowupType.comments = '';
        this.FollowupType.expected_date = '';*/
        this.myform.reset();
    }

    followUpDetails(id) {
        this.followUpList.forEach(item => {
            if (item['id'] === id) {
                this.followupitem = item;
            }
        });
       // console.log(this.followupitem);
    }

    updateFollowItem(id) {
        const data = {
            id: id,
            observation_id : this.observation_id
        };
        console.log(data);
        this.popService.updatefollowitem(data).subscribe((putResult: any) => {
            if (putResult.status === 'success') {
                this.addForm = false;
                this.viewForm = true;
                this.editForm = false;
                this.popService.getfollowlist(this.observation_id).subscribe((resultValue: any) => {
                    this.followUpList = resultValue.data;
                });
            }
        });
        this.FollowupType.followup_item = '';
        this.FollowupType.assigned_to = '';
        this.FollowupType.comments = '';
        this.FollowupType.expected_date = '';
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit(): void {
        this.popService.getfollowlist(this.observation_id).subscribe((resultValue: any) => {
            this.followUpList = resultValue.data;

        });
    }

}

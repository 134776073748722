<div class="col-lg-4 col-md-4 col-sm-4 no-float">
    <div class="login-wrapper">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 login-logo-wrapper">
                <img class="login-logo" src="./assets/imgs/SPI-logo-home.png" />

                <h4 class="login-title">{{'Sign In' | translate}}</h4>

                <form id='login-form' #f='ngForm' novalidate (ngSubmit)='f.form.valid && signIn()'>
                    <div class='form-group'>
                        <input #emailAddress type='text' tabindex="1" autocomplete="off" class='form-control m-0' name='email'
                            [(ngModel)]='loginModel.email' #email='ngModel' [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
                            (input)="clearMessage()" placeholder="{{'Enter UserName' | translate}}" required email />

                        <div *ngIf='f.submitted && email.invalid' class='invalid-feedback text-left'>
                            <div *ngIf="email.errors.required">Please enter email address</div>
                            <div *ngIf="email.errors.email">Please enter valid email address</div>
                        </div>

                    </div>
                    <div class='form-group'>
                        <input type='password' tabindex="2" autocomplete="off" class='form-control m-0' name='password'
                            [(ngModel)]='loginModel.password' #password='ngModel' [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
                            (input)="clearMessage()" placeholder="{{'Enter Password' | translate}}" required />

                        <div *ngIf='f.submitted && password.invalid' class='invalid-feedback text-left'>
                            <div *ngIf='password.errors.required'>Please enter password</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <button class='btn btn-primary' name='login-submit' id='login-submit' tabindex="3">
                            {{'Button.SignIn' | translate}} </button> 
                            <button class='btn btn-link' name='forgot-password-submit' id='forgot-password-submit' tabindex="4"
                            (click)="redirectToForgotPassword()">
                            {{'Button.ForgotPassword' | translate}}</button>

                        <div class='invalid-feedback'>
                            <div>{{errorMessage}}</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> 


<div class="col-lg-4 col-md-4 col-sm-4 no-float">
    <div class="login-wrapper">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 login-logo-wrapper">
                <img class="login-logo" src="./assets/imgs/SPI-logo-home.png" />

                <h4 class="login-title">{{'Forgot Password' | translate}}</h4>

                <form id='forgot-password-form' #f='ngForm' novalidate (ngSubmit)='f.form.valid && forgotPassword()'>
                    <div class='form-group'>
                        <input type='text' #emailAddress autocomplete="off" tabindex="1" class='form-control m-0' name='email' [(ngModel)]='forgotPasswordDto.email'
                            #email='ngModel' (input)="clearMessage()" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" placeholder="{{'Enter Email Address' | translate}}"
                            required email />

                        <div *ngIf='f.submitted && email.invalid' class='invalid-feedback text-left'>
                            <div *ngIf='email.errors.required'>Please enter your email address</div>
                            <div *ngIf="email.errors.email">Please enter valid email address</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <button class='btn btn-primary' name='forgot-password-submit' id='forgot-password-submit'
                            tabindex="2">
                            {{'Button.Submit' | translate}}</button>

                        <button class='btn btn-link' name='login-submit' id='login-submit' tabindex="3" (click)="redirectToLogin()">
                            {{'Button.SignIn' | translate}} </button>

                        <div class='invalid-feedback'>
                            <div>{{errorMessage}}</div>
                        </div>

                        <div class="success-message">
                            <div>{{successMessage}}</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UrlConstant } from 'src/app/constants/url.const';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private authService: AuthenticationService, private router: Router) {
    }

    canActivate() {

        const isUserLoggedIn = this.authService.isUserLoggedIn();

        if (!isUserLoggedIn) {
            this.router.navigate([UrlConstant.Unauthenticated]);
        }

        return isUserLoggedIn;
    }
}

import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { environment } from '../../../environments/environment';

const noop = (): any => undefined;

@Injectable()
export class LoggerService implements Logger {

    get info() {
        if (!environment.production) {
            return console.info.bind(console);
        } else {
            return noop;
        }
    }

    get warn() {
        if (!environment.production) {
            return console.warn.bind(console);
        } else {
            return noop;
        }
    }

    get error() {
        if (!environment.production) {
            return console.error.bind(console);
        } else {
            return noop;
        }
    }

    invoke(type: string, args?: any): void {
        const logFn: Function = (console)[type] || console.log || noop;
        logFn.apply(console, [args]);
    }
}

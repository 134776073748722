<nav class="navbar navbar-expand-lg navbar-expand-sm navbar-light bg-light navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-header">
            <a class="navbar-brand pointer logo" routerLink="reports">
                <img src="./assets/imgs/SPI-logo-home.png"/>
            </a>
            <a *ngIf='userdetailslist?.organization_logo' class="navbar-brand pointer org_logo" routerLink="reports">
                <img src="{{ userdetailslist?.organization_logo }}" alt="org-logo-img"/>
            </a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="loggedInUser != null">
                    <a class="nav-link navbar-brand" [ngbTooltip]="loggedInUser.email"
                       placement="bottom" (click)="open(content)">
                        {{ loggedInUser.email }} <i class="fa fa-user-circle-o mr-1"></i></a>
                </li>
                <li class="dropdown" *ngIf="loggedInUser != null">
                    <a class="nav-link navbar-brand" (click)="signOut()" [ngbTooltip]="'Tooltip.SignOut' | translate"
                       placement="bottom">
                        <i class="fa fa-power-off mr-0"></i>
                    </a>
<!--                                <ul class="dropdown-menu">-->
<!--                                  <li><a href="#">Action</a></li>-->
<!--                                  <li><a href="#">Another action</a></li>-->
<!--                                  <li><a href="#">Something else here</a></li>-->
<!--                                  <li role="separator" class="divider"></li>-->
<!--                                  <li class="dropdown-header">Nav header</li>-->
<!--                                  <li><a href="#">Separated link</a></li>-->
<!--                                  <li><a href="#">One more separated link</a></li>-->
<!--                                </ul>-->
                </li>
            </ul>
        </div><!--/.nav-collapse -->
    </div>
</nav>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title">
            User Details
        </h4>
        <button type="button" class="close"
                aria-label="Close" (click)=
                        "modal.dismiss('Cross click')"> close
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <div>
                    <div class="col-lg-8 col-sm-12 col-md-8">

                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-md-6" style="font-weight: bold">
                                <p>{{ "First Name" | translate }}</p>
                                <p>{{ "Last Name"  | translate }}</p>
                                <p>{{ "Email" | translate }}</p>
                                <p>{{ "Job Title" | translate }}</p>
                                <p *ngIf="userdetailslist.role == 'L3'">{{ "School Name" }}</p>
                                <p>{{ "Parent Organization" }}</p>

                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6">
                                <p>{{ userdetailslist.first_name }}</p>
                                <p>{{ userdetailslist.last_name }}</p>
                                <p>{{ userdetailslist.email }} </p>
                                <p>{{ userdetailslist.role == 'L3' ? 'Principal' : 'Correspondent'}}</p>
                                <p *ngIf="userdetailslist.role == 'L3'">{{ userdetailslist.branch_organization_name  }}</p>
                                <p>{{ userdetailslist.organization_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <button class="btn btn-obs" (click)="open(editcontent)" (click)=
                "modal.dismiss('Cross click')">Edit</button>
    </div>

</ng-template>

<ng-template #editcontent let-modal>

    <div class="modal-header">
        <h4 class="modal-title"
            id="modalbasic-title">
            User Details
        </h4>
        <button type="button" class="close"
                aria-label="Close" (click)=
                        "modal.dismiss('Cross click')"> close
        </button>
    </div>
    <div class="modal-body">
                <form
                id="followup-form"
                #f="ngForm" novalidate
                (ngSubmit)="f.form.valid && uploading(imageuploaded)"
                style="display: block">

            <span *ngIf="f.submitted && f.invalid" > Please enter all mandatory fields </span>
            <div class="col p-0 mt-3">
                <div class="col-lg-8 col-sm-12 col-md-8">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6" >
                            <label>First name  <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    #followupTypeElement
                                    placeholder="First name"
                                    class="form-control"
                                    name="first_name"
                                    [(ngModel)]="userdetailslist.first_name"
                                    required
                                    #first_name="ngModel"
                                    [ngClass]="{
                'is-invalid': f.submitted && first_name.invalid
              }"
                            />

                            <br>

                            <label>Last Name  <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    #followupTypeElement
                                    placeholder="Last name"
                                    class="form-control"
                                    name="last_name"
                                    [(ngModel)]="userdetailslist.last_name"
                                    required
                                    #last_name="ngModel"
                                    [ngClass]="{
                'is-invalid': f.submitted && last_name.invalid
              }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    name="organizationLogo"
                   [(ngModel)]="userdetailslist.organization_logo"
                    #organizationLogo="ngModel"
                    #imageuploaded
                    #followupTypeElement
                    (change)="imageChange($event)"
                    [ngClass]="{'is-invalid': f.submitted && organizationLogo.invalid}"
                    required/>

                    <img
                         class="image-holder" height="100px" width="100px" [src]=getSantizeUrl(userdetailslist.organization_logo) />
            <div class="modal-footer">
                <button type="submit"
                        class="btn btn-danger">
                    Submit
                </button>
            </div>
            <div class="clearfix"></div>
        </form>
    </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { MenuItems } from 'src/app/configs/navigation-items';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { Menu } from '../../../../core/entities/menu';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
    selector: 'app-left-nav',
    templateUrl: './left-nav.component.html',
    styleUrls: ['./left-nav.component.scss']
})

export class LeftNavComponent extends BaseComponent implements OnInit {

    menuItems: Menu[] = [];

    constructor(private authorizationService: AuthorizationService) {
        super();
    }

    public ngOnInit() {

        const isUserLoggedIn = this.authenticationService.accessToken;

        if (isUserLoggedIn) {
            this.menuItems = this.authorizationService.filterAuthorizedMenuItems(MenuItems);
        }

        this.authenticationService.loggedInUserEvent.subscribe(loggedInUserInfo => {
            if (loggedInUserInfo) {
                this.menuItems = this.authorizationService.filterAuthorizedMenuItems(MenuItems);
            } else {
                this.menuItems = [];
            }
        });
    }
}

import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthorizationService } from '../auth/authorization.service';
import { Injectable } from '@angular/core';
import { UrlConstant } from 'src/app/constants/url.const';

@Injectable()
export class AuthorizationGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthorizationService) {
    }

    canActivate(route: ActivatedRouteSnapshot) {

        let isAuthorized = false;

        if (route.data.moduleLevelPermission) {
            isAuthorized = this.authService.hasModuleLevelPermission(route.data.moduleLevelPermission);

        } else if (route.data.pageLevelPermission) {
            isAuthorized = this.authService.hasPageLevelPermission(route.data.pageLevelPermission);
        }

        if (!isAuthorized) {
            this.router.navigate([UrlConstant.Unauthorized]);
        }

        return isAuthorized;
    }
}

import { JsonSerializer } from '../serialization/json-serializer';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorage {

    constructor(private jsonSerializer: JsonSerializer) {
    }

    set(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    setObject<T>(key: string, value: T) {
        localStorage.setItem(key, this.jsonSerializer.serialization<T>(value));
    }

    get(key: string): any {
        return localStorage.getItem(key);
    }

    getObject<T>(key: string): T {
        const tempObj = localStorage.getItem(key);
        return tempObj ? this.jsonSerializer.deserialization<T>(tempObj) : null;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clearAll() {
        localStorage.clear();
    }
}

import { NgModule } from '@angular/core';
import { LeftNavComponent } from './nav/left-nav/left-nav.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared.module';
import { PageLayoutComponent } from './page-layout.component';

@NgModule({

    declarations: [
        /* Components */
        PageLayoutComponent,
        HeaderComponent,
        FooterComponent,
        LeftNavComponent
    ],
    imports: [
        /* Modules */
        SharedModule
    ],
    exports: [
        /* Components */
        PageLayoutComponent
    ]
})

export class PageLayoutModule { }

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../auth/authorization.service';

@Directive({
    selector: '[hasPermission]'
})

export class HasPermissionDirective {

    constructor(private authorizationService: AuthorizationService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef) {
    }

    @Input() set hasPermission(featureName: string) {

        const hasFeaturePermission = this.authorizationService.hasFeatureLevelPermission(featureName);

        if (hasFeaturePermission) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UrlConstant } from 'src/app/constants/url.const';

@Component({
    templateUrl: './custom-error.component.html',
    styleUrls: ['../error-pages.scss']
})
export class ErrorComponent {

    constructor(private router: Router) {
    }

    redirectToHomePage() {
        this.router.navigate([UrlConstant.Reports]);
    }
}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
import { LoggerService } from '../logger/logger.service';
import { CoreConstants } from '../core.constants';
import { TranslationKeys } from 'src/app/constants/translationKeys.const';
import { AuthenticationService } from '../auth/authentication.service';

export class HttpRequestInterceptor implements HttpInterceptor {

    private requestCount: number = 0;

    constructor(private loaderService: LoaderService, private authService: AuthenticationService, private loggerService: LoggerService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.requestCount = this.requestCount + 1;

        this.loaderService.showPageLoaderWithMessage(TranslationKeys.Loading);

        const newRequest = request.clone({
            setHeaders: this.getHeaders()
        });

        return next.handle(newRequest)
            .pipe(

                map(event => {
                    if (event instanceof HttpResponse) {
                        // code
                        // this.loggerService.info(event.body);
                    }
                    return event;
                }),
                catchError(error => {

                    // Stop loader if any exception occurs
                    this.requestCount = 0;

                    return throwError(error);
                }),
                finalize(() => {

                    // Stop loader if any exception occurs
                    this.requestCount = this.requestCount - 1;

                    if (this.requestCount < 0) {
                        this.requestCount = 0;
                    }

                    if (this.requestCount === 0) {
                        this.loaderService.showPageLoader(false);
                    }
                })
            );
    }

    private getHeaders() {

        return {
            Auth: `${this.authService.accessToken}`,
           // ContentType: CoreConstants.ContentTypes.Json,
           // CacheControl: 'no-cache, no-store, must-revalidate',
           // Pragma: 'no-cache',
        };
    }
}


import { Injectable } from "@angular/core";
@Injectable()
export class ConfirmantionService {

    confirm(message: string, onYesCallbackFunc: Function, onNoCallbackFunc: Function) {

        if (window.confirm(message)) {
            onYesCallbackFunc();
        } else {
            onNoCallbackFunc();
        }
    }
}

import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {BaseComponent} from 'src/app/shared/components/base.component';
import {Router} from '@angular/router';
import {UrlConstant} from 'src/app/constants/url.const';
import {LoginDto} from './login.model';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BaseComponent implements OnInit, AfterViewInit {
    closeResult: string;
    @ViewChild('emailAddress') emailAddressElement: ElementRef;

    loginModel: LoginDto = new LoginDto();

    errorMessage: string;

    constructor(private router: Router) {
        super();
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        if (this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([UrlConstant.Reports]);
        }
    }

    ngAfterViewInit() {
        this.emailAddressElement.nativeElement.focus();
    }



    signIn() {

        this.errorMessage = '';
        this.authenticationService.signIn(this.loginModel)
            .subscribe(
                result => {
                    if (result) {
                        // console.log(this.authenticationService.loggedInUserInfo);
                        if (this.authenticationService.loggedInUserInfo['flag']) {
                            this.router.navigate([UrlConstant.ResetPassword]);
                        } else {
                            this.router.navigate([this.authenticationService.loggedInUserInfo.role === 'L1' ? UrlConstant.Organization : UrlConstant.Reports]);
                        }
                    }
                },
                errorResponse => {
                    this.errorMessage = errorResponse.error.data;
                });
    }

    redirectToForgotPassword() {
        this.router.navigate([UrlConstant.ForgotPassword]);
    }

    clearMessage() {
        this.errorMessage = '';
    }

    updatePassword() {
        this.router.navigate([UrlConstant.Reports]);
    }
}

import {HttpClientService} from 'src/app/core/http-client/http-client.service';
import {Injectable} from '@angular/core';
import {HttpRequestParams} from 'src/app/core/http-client/http-request-params';
import {HttpMethods} from 'src/app/core/http-client/http-methods.enum';
import {Observable} from 'rxjs';
import {User} from './user';
import {School} from '../school/school';
import {AuthenticationService} from '../../core/auth/authentication.service';

@Injectable()
export class UserService {

    constructor(private httpClientService: HttpClientService,
                private authenticationService: AuthenticationService) {
    }

    getAllSchools(): Observable<School[]> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.GET;
        httpParamRequest.url = 'organization';

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    getAllUsers(): Observable<User[]> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.GET;
        httpParamRequest.url = 'user';

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    addUser(user: any): Observable<boolean> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.POST;
        httpParamRequest.url = 'adduser';
        httpParamRequest.params = user;

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    updateUser(user: any, id: string): Observable<boolean> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.PUT;
        httpParamRequest.url = `updateuser?user_id=${id}`;
        httpParamRequest.params = user;

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    bulkUpload(user: any): Observable<boolean> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.POST;
        httpParamRequest.url = 'signup/bulk/';
        httpParamRequest.params = user;

        return this.httpClientService.httpRequest(httpParamRequest);
    }


    getUser(userId: string): Observable<User> {
        console.log(userId);
        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.GET;
        httpParamRequest.url = `user?user_id=${userId}`;

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    getUserDetails(userId: string): Observable<User> {
      //  console.log(userId);
        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.GET;
        httpParamRequest.url = `userdetails?user_id=${userId}`;

        return this.httpClientService.httpRequest(httpParamRequest);
    }

    updateUserDetails(user: any): Observable<boolean> {

        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.PUT;
        httpParamRequest.url = `updateuserdetails`;
        httpParamRequest.params = user;

        return this.httpClientService.httpRequest(httpParamRequest);
    }
}

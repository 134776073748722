import { Injectable } from '@angular/core';

@Injectable()
export class JsonSerializer {

    serialization<T>(obj: T): any {
        return JSON.stringify(obj);
    }

    deserialization<T>(text: string): T {
        return JSON.parse(text);
    }
}

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from '../base.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html'
})

export class FileUploadComponent extends BaseComponent {

    @Input() fileName: string;

    @Input() filePath: string;

    @Output() onUploadFile: EventEmitter<string[]> = new EventEmitter<string[]>();

    selectedFile: File;

    constructor() {
        super();
    }

    validateFile(file: File) {

        this.selectedFile = file;

        if (!file) {
            alert('Please select file');
            return false;
        }

        const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (fileExtension !== 'csv') {
            alert('Selected file format is not supported.');
            return false;
        }

        return true;
    }

    uploadFile() {

        if (this.validateFile(this.selectedFile)) {
            const reader = new FileReader();
            reader.onload = () => {
                this.onUploadFile.emit(reader.result.toString().split(/\r|\n|\r/));
            };
            reader.readAsText(this.selectedFile, 'utf8');
        }
    }
}

import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartService } from './chart.service';
import { Chart } from './chart';
declare var require: any;
require('highcharts-no-data-to-display')(Highcharts);
require('highcharts-drilldown')(Highcharts);

@Component({
    selector: 'app-chart',
    template: `<div #chartTarget></div>`
})

export class ChartComponent implements OnChanges {

    @Input() showChart: boolean;

    @Input() chartData: Chart;

    @Input() chartType: string;

    @Input() chartOptions: Highcharts.Options;

    @ViewChild('chartTarget') chartTarget: ElementRef;

    constructor(private chartService: ChartService) {
    }

    ngOnChanges(changes: SimpleChanges) {

        for (const propName in changes) {

            if (propName === 'showChart' && changes[propName].currentValue === true) {

                if (this.chartData && this.chartType) {

                    this.loadChart();
                }
            }
        }
    }

    private loadChart() {

        this.chartTarget.nativeElement.innerHTML = '';

        const chartOptions: any = this.chartService.getChartOptions(this.chartType, this.chartOptions, this.chartData);

        setTimeout(() => Highcharts.chart(this.chartTarget.nativeElement, chartOptions));
    }

}

import { Component, OnInit, NgZone } from '@angular/core';
import { AlertMessage } from './alert-message';
import { AlertMessageService } from '../../services/alert-message.service';
import { AlertMessageType } from './alert-message-types.enum';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
    styleUrls: ['./alert-message.component.scss']
})

export class AlertMessageComponent implements OnInit {

    public alertMessages: AlertMessage[] = [];

    constructor(private alertMessageService: AlertMessageService, private zone: NgZone) {
    }

    ngOnInit() {

        this.alertMessageService.alertMessageEvent.subscribe((alertMessage: AlertMessage) => {

            if (alertMessage.alertType === AlertMessageType.None) {
                this.alertMessages = [];
            } else {
                this.alertMessages.push(alertMessage);
                this.startTimer(alertMessage);
            }
        });
    }

    startTimer(alertMessage: AlertMessage) {

        alertMessage.timeout = setTimeout(() => {
            this.remove(alertMessage);
        }, 2000);

        this.zone.run(function () { });
    }


    stopTimer(alertMessage: AlertMessage) {
        if (alertMessage.timeout) {
            clearTimeout(alertMessage.timeout);
            alertMessage.timeout = null;
        }
    }

    restartTimer(alertMessage: AlertMessage) {
        if (!alertMessage.timeout) {
            this.startTimer(alertMessage);
        }
    }

    remove(alertMessage: AlertMessage) {
        this.alertMessages = this.alertMessages.filter(x => x !== alertMessage);
        this.zone.run(function () { });
    }

    cssClass(alertMessage: AlertMessage) {

        if (!alertMessage) {
            return;
        }

        switch (alertMessage.alertType) {

            case AlertMessageType.Success:
                return 'toast-success';
            case AlertMessageType.Error:
                return 'toast-error';
            case AlertMessageType.Information:
                return 'toast-info';
            case AlertMessageType.Warning:
                return 'toast-warning';
            case AlertMessageType.Wait:
                return 'toast-wait';
        }
    }

    trackById(index, item: AlertMessage) {
        return item || item.id;
    }
}

import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ForgotPasswordDto } from './forgot-password-model';
import { Router } from '@angular/router';
import { UrlConstant } from 'src/app/constants/url.const';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent extends BaseComponent implements AfterViewInit {

    @ViewChild('emailAddress') emailElement: ElementRef;

    forgotPasswordDto: ForgotPasswordDto = new ForgotPasswordDto();

    errorMessage: string;

    successMessage: string;

    constructor(private router: Router) {
        super();
    }

    ngAfterViewInit() {
        this.emailElement.nativeElement.focus();
    }

    forgotPassword() {

        this.errorMessage = '';
        this.successMessage = '';

        this.authenticationService.forgotPassword(this.forgotPasswordDto)
            .subscribe(
                result => {
                    if (result) {

                        if (result.status === 'valid') {
                            this.successMessage = result.data;
                            this.redirectToLogin();
                        } else {
                            this.errorMessage = result.data;
                        }
                    }
                },
                errorResponse => {
                    this.errorMessage = errorResponse.error.data;
                });
    }

    redirectToLogin() {
        this.router.navigate([UrlConstant.Login]);
    }

    clearMessage() {
        this.errorMessage = '';
        this.successMessage = '';
    }
}

import {Component, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {BaseComponent} from 'src/app/shared/components/base.component';
import {UrlConstant} from 'src/app/constants/url.const';
import {ResetPasswordDto} from './reset-password-model';
import {Router} from '@angular/router';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent extends BaseComponent implements AfterViewInit {
    errorMessage: string;

    resetPasswordModal: ResetPasswordDto = new ResetPasswordDto();

    successMessage: string;
    smallLoading: boolean = false;

    constructor(private router: Router) {
        super();
    }

    ngAfterViewInit() {
        // this.emailElement.nativeElement.focus();
    }

    resetPassword() {
        const resetPasswordData = {
            'email': this.authenticationService.loggedInUserInfo.email,
            'old_password': this.resetPasswordModal.current_password,
            'new_password': this.resetPasswordModal.new_password
        };
        if (this.resetPasswordModal.new_password.length < 8) {
            this.errorMessage = 'New password should be minimum 8 characters';
            return false;
        } else if (this.resetPasswordModal.new_password !== this.resetPasswordModal.confirm_password) {
            this.errorMessage = 'New password and confirm password should be same.';
            return false;
        }
        this.authenticationService.resetPassword(resetPasswordData).subscribe((response) => {
            if (response.status === 'success') {
                this.successMessage = response.data;
                this.smallLoading = true;
                setTimeout(() => {
                    this.signOut();
                }, 3500);
            } else {
                this.errorMessage = response.data;
            }
        }, (errorResponse) => {
            this.errorMessage = errorResponse.error.data;
        });
        this.signOut();
        this.clearMessage();
    }

    public signOut() {
        this.authenticationService.signOut();
        this.router.navigate([UrlConstant.Login]);
    }

   /* async delay(ms: number) {
        await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
    }*/

    clearMessage() {
        this.errorMessage = '';
        this.successMessage = '';
    }
}

import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../components/base.component';
import {LoggedInUserInfo} from 'src/app/core/entities/loggedInUser';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-page-layout',
    templateUrl: './page-layout.component.html'
})

export class PageLayoutComponent extends BaseComponent implements OnInit {

    loggedInUser: LoggedInUserInfo;

    isLoggedIn: boolean;
    url: string;

    constructor(private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.loggedInUser = this.authenticationService.loggedInUserInfo;
        this.activatedRoute.url.subscribe(activeUrl => {
            this.url = window.location.pathname;
        });
        this.isLoggedIn = this.authenticationService.accessToken != null;
        this.authenticationService.loggedInUserEvent.subscribe(loggedInUserInfo => {
            this.loggedInUser = loggedInUserInfo;
            this.isLoggedIn = this.authenticationService.accessToken != null;
        });
    }
}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppConstant} from 'src/app/constants/app.const';
import {environment} from 'src/environments/environment.prod';
import {CoreConstants} from '../core.constants';
import {LoggedInUserInfo} from '../entities/loggedInUser';
import {LocalStorage} from '../stores/local-storage';
import {LoginDto} from 'src/app/modules/login/login.model';
import {ForgotPasswordDto} from 'src/app/modules/forgot-password/forgot-password-model';

@Injectable()
export class AuthenticationService {

    loggedInUserEvent: EventEmitter<LoggedInUserInfo> = new EventEmitter<LoggedInUserInfo>();

    constructor(private httpClient: HttpClient, private localStorage: LocalStorage, private jwtHelperService: JwtHelperService) {
    }

    signIn(loginModel: LoginDto): Observable<boolean> {

        const url = `${environment.webApiUrl}/login`;

        return this.httpClient.post(url, loginModel, {headers: {'Content-Type': 'application/json'}}).pipe(
            map((response: any) => {

                if (response && response.status !== 'fail') {
                    const accessToken = response.data.auth_token;

                    if (accessToken && !this.jwtHelperService.isTokenExpired(accessToken)) {
                        this.accessToken = response.data.auth_token;
                        const loggedInUserInfo = response.data;
                        this.loggedInUserInfo = loggedInUserInfo;
                        this.loggedInUserEvent.next(this.loggedInUserInfo);
                        return true;
                    }
                } else {
                    catchError((error: Response | any) => {
                        throw error;
                    });
                }
                return false;
            }),
            catchError((error: Response | any) => {
                throw error;
            })
        );
    }

    signOut(): void {
        this.localStorage.clearAll();
        this.loggedInUserEvent.next();
    }

    // For receiving the password reset email code
    forgotPassword(forgotPassword: ForgotPasswordDto): Observable<any> {

        const url = `${environment.webApiUrl}/forgetpassword`;

        return this.httpClient.post(url, forgotPassword, {headers: {'Content-Type': 'application/json'}}).pipe(
            map((response: any) => {

                if (response && response.data) {


                }

                return response;
            }),
            catchError((error: Response | any) => {
                throw error;
            })
        );
    }

    // For resetting the password
    resetPassword(resetPasswordData): Observable<any> {
        const url = `${environment.webApiUrl}/resetpassword`;

        return this.httpClient.put(url, resetPasswordData, {headers: {'Content-Type': 'application/json'}}).pipe(
            map((response: any) => {
                if (response && response.data) {


                }
                return response;
            }),
            catchError((error: Response | any) => {
                throw error;
            })
        );
    }

    set accessToken(accessToken: string) {
        this.localStorage.set(CoreConstants.AccessToken, accessToken);
    }

    get accessToken(): string {
        return this.localStorage.get(CoreConstants.AccessToken);
    }

    set loggedInUserInfo(loggedInUserInfo: LoggedInUserInfo) {
        this.localStorage.setObject<LoggedInUserInfo>(CoreConstants.LoggedInUserInfo, loggedInUserInfo);
    }

    get loggedInUserInfo(): LoggedInUserInfo {
        return this.localStorage.getObject<LoggedInUserInfo>(CoreConstants.LoggedInUserInfo);
    }

    isUserLoggedIn(): boolean {
        if (!this.jwtHelperService.isTokenExpired() && this.loggedInUserInfo && !this.loggedInUserInfo['flag']) {

            if (this.accessToken) {
                return true;
            }
        }

        this.loggedInUserEvent.next();
        return false;
    }


    isL2(): boolean {
        return this.loggedInUserInfo.role === AppConstant.Roles.L2;
    }

    getUserOrganization(): string {
        return this.loggedInUserInfo.organization_id;
    }

}

<div class="row">
    <div class="col-lg-12">
        <div class="message-area text-center">
            <h1>
                <i class="fa fa-ban"></i> {{ 'ErrorPage.PageNotFoundTitle' | translate}}
            </h1>
            
            <p>{{ 'ErrorPage.PageNotFoundBody' | translate}}</p>

            <h3>
                Please visit the <a class="text-primary" (click)="redirectToHomePage()">HomePage</a> or contact Administrator for more details.
            </h3>
        </div>
    </div>
</div>
<nav class="sidebar">
    <ul *ngIf="menuItems">
        <li *ngFor="let menuItem of menuItems">
            <a [routerLink]="menuItem.route" routerLinkActive="active">
                <i [ngClass]="menuItem.icon"></i>
                <span>{{menuItem.name | translate}} </span>
            </a>
        </li>
        <li>
            <a href="https://s3.us-east-2.amazonaws.com/safeschool-beta/Admin+%26+Super+admin+User+guide+safe+school.pdf" target="_blank">
                <i class="fa fa-link"></i>
                <span>Reference Links</span>
            </a>
        </li>
    </ul>
</nav>
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

    showPageLoaderEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    showPageLoaderWithMessageEvent: EventEmitter<string> = new EventEmitter<string>();

    showPageLoader(flag: boolean) {
        this.showPageLoaderEvent.emit(flag);
    }

    showPageLoaderWithMessage(message: string) {
        this.showPageLoaderWithMessageEvent.emit(message);
    }
}

import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-pager',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit, OnChanges {

    @Input() gridItems: any[];

    @Input() isServerSidePagination: boolean = false;

    @Output() onPageChange: EventEmitter<any> = new EventEmitter<any>();

    @Output() exportAsExcel: EventEmitter<string> = new EventEmitter<string>();

    @Input() totalItems: number;

    totalPages: number;

    currentPage: number = 1;

    startPage: number;

    endPage: number;

    startIndex: number;

    endIndex: number;

    pages: number[] = [];

    pageSize: number = 10;

    pageSizes: number[];

    excelFormat: string = 'csv';
    showExportOption: boolean = false;

    ngOnInit() {
        this.pageSizes = [10];
    }

    constructor(private activatedRoute: ActivatedRoute,
    ) {
        this.showExportOption = this.activatedRoute.snapshot['_routerState']['url'] === '/observations/list';
    }

    ngOnChanges(changes: SimpleChanges) {

        for (const propName in changes) {

            if (propName === 'gridItems' && changes[propName].currentValue !== changes[propName].previousValue) {

                setTimeout(() => this.setPage(this.currentPage));
            }
        }
    }

    setPager() {
        this.totalItems = this.isServerSidePagination ? this.totalItems : this.gridItems.length;

        this.totalPages = Math.ceil(this.totalItems / this.pageSize);

        // ensure current page isn't out of range
        if (this.currentPage < 1) {
            this.currentPage = 1;
        } else if (this.currentPage > this.totalPages) {
            this.currentPage = this.totalPages;
        }
        if (this.totalPages <= 10) {
            // less than 10 total pages so show all
            this.startPage = 1;
            this.endPage = this.totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (this.currentPage <= 6) {
                this.startPage = 1;
                this.endPage = 10;
            } else if (this.currentPage + 4 >= this.totalPages) {
                this.startPage = this.totalPages - 9;
                this.endPage = this.totalPages;
            } else {
                this.startPage = this.currentPage - 5;
                this.endPage = this.currentPage + 4;
            }
        }

        // calculate start and end item indexes
        this.startIndex = (this.currentPage - 1) * this.pageSize;
        this.endIndex = Math.min(this.startIndex + this.pageSize - 1, this.totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        this.pages = Array.from(Array((this.endPage + 1) - this.startPage).keys()).map(i => this.startPage + i);

    }

    setPage(page: number) {

        if (this.isServerSidePagination && page !== this.currentPage) {
            this.onPageChange.emit(page);
        }

        this.currentPage = page;

        // get pager object from service
        this.setPager();

        if (!this.isServerSidePagination) {
            const pagedItems = this.gridItems.slice(this.startIndex, this.endIndex + 1);
            this.onPageChange.emit(pagedItems);
        }
    }

    onPageSizeChange(pageSize: string) {

        this.pageSize = parseInt(pageSize, null);

        this.setPage(this.currentPage);

    }

    onChangeExcelFormat() {
        this.exportAsExcel.emit(this.excelFormat);
    }
}

import { CurrencyPipe, getLocaleCurrencySymbol } from '@angular/common';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'currency'
})

export class CustomCurrencyPipe extends CurrencyPipe {

    constructor(private translateService: TranslateService) {
        super(translateService.currentLang);
    }

    transform(value: number, local?: string, digitInfos?: string, symbol?: string): any {
        const currencyCode = getLocaleCurrencySymbol(local || this.translateService.currentLang);
        return super.transform(value, currencyCode, symbol || 'symbol', digitInfos || '1.0-0', local || this.translateService.currentLang);
    }
}

import { LanguageHelper } from './language.helper';
import { Injectable } from '@angular/core';
import { LocalStorage } from '../stores/local-storage';
import { CoreConstants } from '../core.constants';
import { getLocaleCurrencySymbol, getLocaleCurrencyName, getLocaleDateTimeFormat, FormatWidth } from '@angular/common';
import { Locale, Language } from './locale';

@Injectable()
export class LocaleService {

    constructor(private localStorage: LocalStorage) {
    }

    setCurrentLocale(languageCode: string) {

        const locale = new Locale();

        locale.language = LanguageHelper.getLanguageByCode(languageCode);
        locale.currency = {
            currencyName: getLocaleCurrencyName(languageCode),
            currencySymbol: getLocaleCurrencySymbol(languageCode)
        };

        this.localStorage.setObject<Locale>(CoreConstants.CurrentLocale, locale);
    }

    getCurrentLocale(): Locale {
        return this.localStorage.getObject<Locale>(CoreConstants.CurrentLocale);
    }

    getLanguagesByCodes(languageCodes: string[]): Language[] {
        return LanguageHelper.getLanguagesByCodes(languageCodes);
    }

    /// Date-time format that depends on the locale.
    getLocalCurrentDateTimeFormat(languageCode: string, pattern: FormatWidth) {
        return getLocaleDateTimeFormat(languageCode, pattern);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';

@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})

export class PageLoaderComponent implements OnInit {

    public showLoader: boolean;

    public displayMessage: string;

    constructor(private loaderService: LoaderService) {
    }

    ngOnInit() {

        this.loaderService.showPageLoaderEvent.subscribe(flag => {
            this.displayMessage = '';
            this.showLoader = flag;
        });

        this.loaderService.showPageLoaderWithMessageEvent.subscribe(message => {
            this.displayMessage = message;
            this.showLoader = true;
        });
    }
}

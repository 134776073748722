import { appRoutes } from '../app.routing.module';
import { TranslationKeys } from '../constants/translationKeys.const';
import { UrlConstant } from '../constants/url.const';
import { Menu } from '../core/entities/menu';

export const MenuItems: Menu[] = [
    {
        name: TranslationKeys.Menu.Report,
        icon: 'fa fa-area-chart',
        route: UrlConstant.Reports,
        data: appRoutes[8].data
    },
    {
        name: TranslationKeys.Menu.Organization,
        icon: 'fa fa-eye',
        route: UrlConstant.Organization,
        data: appRoutes[5].data
    },
    {
        name: TranslationKeys.Menu.School,
        icon: 'fa fa-institution',
        route: UrlConstant.Schools,
        data: appRoutes[4].data
    },
    {
        name: TranslationKeys.Menu.ObservationType,
        icon: 'fa fa-eye',
        route: UrlConstant.ObservationTypes,
        data: appRoutes[7].data
    },
    {
        name: TranslationKeys.Menu.User,
        icon: 'fa fa-user-secret',
        route: UrlConstant.Users,
        data: appRoutes[2].data
    },
    {
        name: TranslationKeys.Menu.Location,
        icon: 'fa fa-map-marker',
        route: UrlConstant.Locations,
        data: appRoutes[3].data
    },
    {
        name: TranslationKeys.Menu.Student,
        icon: 'fa fa-graduation-cap',
        route: UrlConstant.Students,
        data: appRoutes[6].data
    },
    {
        name: TranslationKeys.Menu.Observation,
        icon: 'fa fa-eye',
        route: UrlConstant.Observations,
        data: appRoutes[9].data
    },
    {
        name: TranslationKeys.Menu.Groups,
        icon: 'fa fa-users',
        route: UrlConstant.Groups,
        data: appRoutes[10].data
    },
];

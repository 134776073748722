import { JsonSerializer } from '../serialization/json-serializer';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class InMemoryStorage {

    private storageObj: [{ key: string, value: string }] = null;

    constructor(private jsonSerializer: JsonSerializer) {
    }

    get(key: string): any {

        const tempObj = _.find(this.storageObj, e => e.key === key);
        return tempObj ? tempObj.value : null;
    }

    getObject<T>(key: string): T {

        const tempObj = _.find(this.storageObj, e => e.key === key);
        return tempObj ? this.jsonSerializer.deserialization<T>(tempObj.value) : null;
    }

    set(key: string, value: any) {

        const tempObj = { key: key, value: value };

        if (this.storageObj) {
            this.storageObj.push(tempObj);
        } else {
            this.storageObj = [tempObj];
        }
    }

    setObject<T>(key: string, value: T) {

        const tempObj = { key: key, value: this.jsonSerializer.serialization<T>(value) };

        if (this.storageObj) {
            this.storageObj.push(tempObj);
        } else {
            this.storageObj = [tempObj];
        }
    }

    remove(key: string) {
        _.remove(this.storageObj, e => e.key === key);
    }

    clearAll() {
        this.storageObj = null;
    }
}

import { JsonSerializer } from '../serialization/json-serializer';
import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorage {

    constructor(private jsonSerializer: JsonSerializer) {
    }

    set(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    setObject<T>(key: string, value: T) {
        sessionStorage.setItem(key, this.jsonSerializer.serialization<T>(value));
    }

    get(key: string): any {
        return sessionStorage.getItem(key);
    }

    getObject<T>(key: string): T {
        const tempObj = sessionStorage.getItem(key);
        return tempObj ? this.jsonSerializer.deserialization<T>(tempObj) : null;
    }

    remove(key: string) {
        sessionStorage.removeItem(key);
    }

    clearAll() {
        sessionStorage.clear();
    }
}

import { Directive, ElementRef, HostListener } from '@angular/core';
import * as _ from 'lodash';

@Directive({
    selector: '[allow-only-alphabets]'
})

export class AllowOnlyAlphabetsDirective {

    constructor(private el: ElementRef<HTMLInputElement>) {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {

        const e = <KeyboardEvent>event;

        if (e.ctrlKey || (e.shiftKey && (([46, 8, 9, 27, 13, 110, 188, 190, 222].indexOf(e.keyCode) !== -1) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)))) {
            e.preventDefault();
        }

        if (e.keyCode === 32 && (<any>e.currentTarget).value !== '') {
            return;
        }

        if (([46, 8, 9, 27, 13, 110, 188, 190, 222].indexOf(e.keyCode) !== -1) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 35 && e.keyCode <= 39) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            return;
        }

        e.preventDefault();
    }

    @HostListener('keyup', ['$event']) onKeyUp(event) {

        if (this.el.nativeElement.value && _.indexOf(this.el.nativeElement.value, ' ') === 0) {
            this.el.nativeElement.value = this.el.nativeElement.value.trim();
        }
    }

    @HostListener('blur', ['$event']) onBlur(event) {

        if (this.el.nativeElement.value) {
            this.el.nativeElement.value = this.el.nativeElement.value.trim();
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { JsonSerializer } from '../serialization/json-serializer';
import { HttpMethods } from './http-methods.enum';
import { HttpRequestParams } from './http-request-params';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class HttpClientService {

    constructor(private httpClient: HttpClient, private jsonSerializer: JsonSerializer, private logger: LoggerService, private authService: AuthenticationService) {
    }

    httpRequest<T>(httpRequestParams: HttpRequestParams): Observable<T> {

        httpRequestParams.url = this.formatUrl(httpRequestParams.url);

        switch (httpRequestParams.httpMethod) {

            case HttpMethods.GET:
                return this.get<T>(httpRequestParams);

            case HttpMethods.POST:
                return this.post<T>(httpRequestParams);

            case HttpMethods.PUT:
                return this.put<T>(httpRequestParams);

            case HttpMethods.DELETE:
                return this.delete<T>(httpRequestParams);

            default:
                this.logger.error('Incorrect Http Method');
        }
    }

    private get<T>(httpRequestParams: HttpRequestParams): Observable<T> {

        return this.httpClient.get<T>(httpRequestParams.url, { headers: httpRequestParams.httpHeaders });
    }

    private post<T>(httpRequestParams: HttpRequestParams): Observable<T> {

        const httpParams = this.jsonSerializer.serialization(httpRequestParams.params);
        return this.httpClient.post<T>(httpRequestParams.url, httpRequestParams.params, { headers: httpRequestParams.httpHeaders });
    }

    private put<T>(httpRequestParams: HttpRequestParams): Observable<T> {

        const httpParams = this.jsonSerializer.serialization(httpRequestParams.params);
        return this.httpClient.put<T>(httpRequestParams.url, httpRequestParams.params, { headers: httpRequestParams.httpHeaders });
    }

    private delete<T>(httpRequestParams: HttpRequestParams): Observable<T> {

        return this.httpClient.delete<T>(httpRequestParams.url, { headers: httpRequestParams.httpHeaders });
    }

    private formatUrl(apiUrl: string) {

       /* if (!this.authService.isL2()) {

            const organization_id = this.authService.loggedInUserInfo.organization_id;

            if (organization_id) {
                apiUrl = apiUrl.indexOf('?') > -1 ? `${apiUrl}&organization_id=${organization_id}` : `${apiUrl}?organization_id=${organization_id}`;
            }
        }*/

        return `${environment.webApiUrl}/${apiUrl}`;

    }
}

<div class="row pagination-wrapper" *ngIf="gridItems">
    <div class="col-lg-11 col-md-10 col-sm-12 no-padding">
        <ul *ngIf="pages && pages.length" class="pagination">
            <li [ngClass]="{disabled:currentPage === 1}">
                <a (click)="setPage(1)">First</a> 
            </li>
            <li [ngClass]="{disabled:currentPage === 1}">
                <a (click)="setPage(currentPage - 1)">Previous</a>
            </li>
            <li *ngFor="let page of pages" [ngClass]="{active:currentPage === page}">
                <a (click)="setPage(page)">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:currentPage === totalPages}">
                <a (click)="setPage(currentPage + 1)">Next</a>
            </li>
            <li [ngClass]="{disabled:currentPage === totalPages}">
                <a (click)="setPage(totalPages)">Last</a> 
            </li>
            <li class="ob-excel-export-li" *ngIf="showExportOption">
                <label>Excel export as</label>&nbsp;
                <select [(ngModel)]="excelFormat" class="ob-excel-export-dropdown btn btn-primary">
                    <option value="csv">csv</option>
                    <option value="xlsx">xlsx</option>
                </select> 
                <button class="btn btn-primary" (click)="onChangeExcelFormat()"> Export </button>
            </li>
        </ul> 
    </div> 

    <div class="col-lg-1 col-md-2 col-sm-12 no-padding align-right">
        <!-- <label>Export As</label>&nbsp;--> 
        <label>Page</label>&nbsp;
        <select [(ngModel)]="pageSize" (change)="onPageSizeChange($event.target.value)">
            <option *ngFor="let pSize of pageSizes" value="{{pSize}}">{{pSize}}</option>
        </select>
    </div>
</div>

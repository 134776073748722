<div id="toast-container" class="toast-top-right" *ngIf="alertMessages">

    <div *ngFor="let alertMessage of alertMessages;trackBy:trackById;" class=" toast {{ cssClass(alertMessage) }}"
        (click)="remove(alertMessage)" (mouseover)="stopTimer(alertMessage)" (mouseout)="restartTimer(alertMessage)">

        <button class="toast-close-button">×</button>

        <div class="toast-title">{{alertMessage.title | translate}}</div>

        <div class="toast-message">{{alertMessage.message | translate}}</div>

    </div>

</div>
import { Component } from '@angular/core';
import { CoreConstants } from '../../../core/core.constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {

    companyName = CoreConstants.CompanyName;
    year = new Date().getUTCFullYear();
}

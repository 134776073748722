export class Locale {
    language: Language;
    currency: Currency;
}

export interface Language {
    languageCode: string;
    shortLanguageCode: string;
    name: string;
    nativeName: string;
}

export interface Currency {
    currencyName: string;
    currencySymbol: string;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlConstant } from 'src/app/constants/url.const';

@Component({
    templateUrl: './unauthenticated.component.html',
    styleUrls: ['../error-pages.scss']
})
export class UnAuthenticatedComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit() {
        setTimeout(() => {
            this.signIn();
        }, 5000);
    }

    signIn() {
        this.router.navigate([UrlConstant.Login]);
    }
}

<div class="main-area" [ngClass]="{'background-image padding-1':!isLoggedIn}">

    <app-header [loggedInUser]="loggedInUser" *ngIf="isLoggedIn"></app-header>

    <app-left-nav *ngIf="isLoggedIn"></app-left-nav>

    <div class="content-area">

        <div class="content-area-wrapper">

            <div class="row">

                <div class="col">

                    <div class="content-area-inner">

                        <ng-content></ng-content>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<app-footer></app-footer>
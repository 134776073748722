<div class="col-lg-4 col-md-4 col-sm-4 no-float">
    <div class="login-wrapper">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 login-logo-wrapper">
                <img class="login-logo" src="./assets/imgs/SPI-logo-home.png" />

                <h4 class="login-title">Reset Password</h4>

                <form id='reset-password-form' #f='ngForm' novalidate (ngSubmit)='f.form.valid && resetPassword()' [noValidate]="true" autocomplete="off">
                    <div class='form-group'>
                        <input type='password' #current_password="ngModel" [(ngModel)]="resetPasswordModal.current_password" tabindex="1" class='form-control m-0' name='current_password'
                            (input)="clearMessage()" placeholder="Enter current password" required  autofocus/>

                        <div *ngIf='f.submitted && current_password.invalid' class='invalid-feedback text-left'>
                            <div *ngIf='current_password.errors.required'>Please enter your current password</div>
                        </div>
                    </div>
                    <div class='form-group'>
                        <input type='password' #new_password="ngModel" [(ngModel)]="resetPasswordModal.new_password" tabindex="2" class='form-control m-0' name='new_password'
                            (input)="clearMessage()" placeholder="Enter new password" required/>

                        <div *ngIf='f.submitted && new_password.invalid' class='invalid-feedback text-left'>
                            <div *ngIf='new_password.errors.required'>Please enter your new password</div> 
                        </div>
                    </div>
                    <div class='form-group'>
                        <input type='password' #confirm_password="ngModel" [(ngModel)]="resetPasswordModal.confirm_password" tabindex="3" class='form-control m-0' name='confirm_password'
                            (input)="clearMessage()" placeholder="Enter confirm password" required/>

                        <div *ngIf='f.submitted && confirm_password.invalid' class='invalid-feedback text-left'>
                            <div *ngIf='confirm_password.errors.required'>Please enter your confirm password</div> 
                        </div>
                    </div> 

                    <div class="form-group">
                        <button class='btn btn-primary' name='reset-password-submit' id='reset-password-submit'
                            tabindex="4">
                            {{'Button.Submit' | translate}}</button>  
                        <div class='invalid-feedback'>
                            <div>{{errorMessage}}</div>
                        </div> 
                        <div class="success-message">
                            <div>{{successMessage}}<span *ngIf="smallLoading"><i class="fa fa-spinner fa-spin"></i> Logged out your session..</span></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

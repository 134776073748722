import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datetime'
})

export class DateTimePipe extends DatePipe implements PipeTransform {

  // ISO 8601 Formatted Date, e.g. 2013-08-24T08:00:00-00:00
  regexMatchForIso8601Date = /[0-9]T[0-9]/;
  format: string = 'MMM DD, YYYY hh:mm a';

  PipeTransform(value: any, args?: any): string {

    if (value && value.indexOf('GMT') > -1) {
      return moment(value).format(this.format);
    }

    if (value) {
      value = value.replace(' ', 'T') + 'Z';
      return this.convertUtcToLocalDisplay(value);
    }

    return '';
  }

  convertUtcToLocalDisplay(value: string) {

    // Check that the date is not in ISO 8601 format,
    // if not then add postfix so can be converted to UTC

    if (!this.regexMatchForIso8601Date.test(value)) {
      value = value + ' UTC';
    }

    if (!moment(value).isValid()) {
      return '';
    }

    return moment(value).format(this.format).toLocaleString();
  }

}

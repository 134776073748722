import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'number'
})

export class CustomDecimalPipe extends DecimalPipe {

    constructor(private translateService: TranslateService) {
        super(translateService.currentLang);
    }

    transform(value: number, local?: string, digitInfos?: string): any {
        return super.transform(value, digitInfos || '1.0-0', local || this.translateService.currentLang);
    }
}

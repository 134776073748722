export const UrlConstant = {

    Login: 'login',
    ForgotPassword: 'forgot-password',
    ResetPassword: 'reset-password',
    Users: 'users',
    Locations: 'locations',
    Organization: 'organization',
    ObservationTypes: 'observation-types',
    Observations: 'observations',
    Schools: 'schools',
    Students: 'students',
    Reports: 'reports',
    Unauthorized: 'unauthorized',
    Unauthenticated: 'unauthenticated',
    ErrorPage: 'error-page',
    PageNotFound: 'page-not-found',
    ObservationDetails: 'observation-details',
    Groups: 'groups',

    List: 'list',
    Add: 'add',
    Edit: 'edit/:id',
    View: 'view/:id'
};

import { Optional, SkipSelf, ModuleWithProviders, NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JsonSerializer } from './serialization/json-serializer';
import { LoggerService } from './logger/logger.service';
import { LoaderService } from './loader/loader.service';
import { HttpClientService } from './http-client/http-client.service';
import { SessionStorage } from './stores/session-storage';
import { LocalStorage } from './stores/local-storage';
import { InMemoryStorage } from './stores/in-memory-storage';
import { AuthorizationService } from './auth/authorization.service';
import { AuthorizationGuard } from './guards/authorization.guard';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { GlobalExceptionHandler } from './exception-handler/global-exception-handler';
import { AuthenticationGuard } from './guards/authentication.guard';
import { AlertMessageService } from '../shared/services/alert-message.service';
import { LocaleService } from './localization/locale.service';
import { AuthenticationService } from './auth/authentication.service';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            deps: [LoaderService, AuthenticationService, LoggerService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalExceptionHandler,
            multi: false
        }
    ]
})

export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                JsonSerializer,
                LoggerService,
                LoaderService,
                HttpClientService,
                SessionStorage,
                LocalStorage,
                InMemoryStorage,
                AuthenticationService,
                AlertMessageService,
                AuthorizationService,
                AuthorizationGuard,
                AuthenticationGuard,
                LocaleService,
            ]
        };
    }
}

export const TranslationKeys = {

    Menu: {
        User: 'Menu.User',
        Location: 'Menu.Location',
        ObservationType: 'Menu.ObservationType',
        Organization: 'Menu.Organization',
        Observation: 'Menu.Observation',
        School: 'Menu.School',
        Student: 'Menu.Student',
        Report: 'Menu.Report',
        Groups: 'Group'
    },

    Loading: 'Loading',

    GroupUpdateSuccessfully: 'SuccessMessage.UpdateGroup',
    GroupSaveSuccessfully: 'SuccessMessage.SaveGroup',
    GroupSaveFailed: 'ErrorMessage.SaveGroup',

    LocationUpdateSuccessfully: 'SuccessMessage.UpdateLocation',
    LocationSaveSuccessfully: 'SuccessMessage.SaveLocation',
    LocationSaveFailed: 'ErrorMessage.SaveLocation',

    OrganizationUpdateSuccessfully: 'SuccessMessage.UpdateOrganization',
    OrganizationSaveSuccessfully: 'SuccessMessage.SaveOrganization',
    OrganizationSaveFailed: 'ErrorMessage.SaveOrganization',

    ObservationTypeUpdateSuccessfully: 'SuccessMessage.UpdateObservationType',
    ObservationTypeSaveSuccessfully: 'SuccessMessage.SaveObservationType',
    ObservationTypeSaveFailed: 'ErrorMessage.SaveObservationType',

    SchoolUpdateSuccessfully: 'SuccessMessage.UpdateSchool',
    SchoolSaveSuccessfully: 'SuccessMessage.SaveSchool',
    SchoolSaveFailed: 'ErrorMessage.SaveSchool',

    UserUpdateSuccessfully: 'SuccessMessage.UpdateUser',
    UserSaveSuccessfully: 'SuccessMessage.SaveUser',
    UserSaveFailed: 'ErrorMessage.SaveUser',

    StudentUpdateSuccessfully: 'SuccessMessage.UpdateStudent',
    StudentSaveSuccessfully: 'SuccessMessage.SaveStudent',
    StudentSaveFailed: 'ErrorMessage.SaveStudent'


};

import { EventEmitter, Injectable } from '@angular/core';
import { AlertMessage } from '../components/alert-message/alert-message';
import { AlertMessageType } from '../components/alert-message/alert-message-types.enum';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class AlertMessageService {

    alertMessageEvent: EventEmitter<AlertMessage> = new EventEmitter<AlertMessage>();

    constructor(private router: Router) {

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // this.clear();
            }
        });
    }

    success(title: string, message: string) {
        this.alert(new AlertMessage(AlertMessageType.Success, title, message));
    }

    error(title: string, message: string) {
        this.alert(new AlertMessage(AlertMessageType.Error, title, message));
    }

    warn(title: string, message: string) {
        this.alert(new AlertMessage(AlertMessageType.Warning, title, message));
    }

    info(title: string, message: string) {
        this.alert(new AlertMessage(AlertMessageType.Information, title, message));
    }

    clear() {
        this.alert(new AlertMessage(AlertMessageType.None, null, null));
    }

    private alert(alertMessage: AlertMessage) {
        this.alertMessageEvent.emit(alertMessage);
    }
}

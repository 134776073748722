import { Languages } from './languages';
import { Language } from './locale';

export class LanguageHelper {

    public static getLanguageByCode(langCode: string): Language {
        const shortLangCode = langCode.slice(0, 2);
        const lang = Languages.isoLangs[shortLangCode];
        return lang ? { languageCode: langCode, shortLanguageCode: shortLangCode, name: lang.name, nativeName: lang.nativeName } : undefined;
    }

    public static getLanguagesByCodes(languageCodes: string[]): Language[] {

        const languages = [];

        for (const langCode of languageCodes) {

            const lng = LanguageHelper.getLanguageByCode(langCode);

            if (!lng) {
                continue;
            }

            languages.push(lng);
        }

        return languages;
    }
}

import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { UrlConstant } from 'src/app/constants/url.const';
import { LoggedInUserInfo } from '../../../core/entities/loggedInUser';
import { BaseComponent } from '../../components/base.component';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../modules/user/user.service';
import {environment} from '../../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import {TranslationKeys} from '../../../constants/translationKeys.const';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends BaseComponent implements OnInit {

    @ViewChild('followupTypeElement') organizationElement: ElementRef<HTMLInputElement>;
    @Input() loggedInUser: LoggedInUserInfo;
    closeResult = '';
    userdetailslist: Record<any, any> = {organization_logo: null};
    role: any;
    isadmin: boolean;

    constructor(private router: Router,
                private modalService: NgbModal, private userService: UserService,
                private sanitizer: DomSanitizer) {
        super();
    }

    s3BucketConfig = new S3(
        {
            accessKeyId: 'AKIA47N26QZ4HVQLYI7B',
            secretAccessKey: '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',
            region: 'us-east-2',
        }
    );

    public getSantizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    signOut(): void {
        this.authenticationService.signOut();
        this.router.navigate([UrlConstant.Login]);
    }
    open(content) {
        this.modalService.open(content,
            {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult =
                `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    uploading(image: HTMLInputElement) {
        console.log(image);
        if (image && image.files && image.files.length  > 0) {
            const file: File = image.files[0];
            const contentType = file.type;
            const params = {
                Bucket: environment.bucketName,
                Key: 'Logo/' + file.name,
                Body: file,
                ContentType: contentType
            };
            this.s3BucketConfig.upload(params, (err, data) => {
                if (err) {
                    console.log('error in uploading');
                    return;
                }
                this.save(data.Location);
            });
        } else {
            this.save();
        }
    }

    imageChange(image: any){
        const reader = new FileReader();
        reader.readAsDataURL(image.target.files[0]);
        reader.onload = () => {
            this.userdetailslist.organization_logo = reader.result;
        }
    }
    save(logo: string = '') {
        const data = {
            first_name: this.userdetailslist.first_name,
            last_name: this.userdetailslist.last_name,
            logo
        };
        this.userService.updateUserDetails(data).subscribe(result => {
            this.alertMessageService.success(TranslationKeys.Menu.User, `User updated successfully.`);
            this.redirectTo();
        },
            error => {
                this.alertMessageService.error(TranslationKeys.Menu.User, error.error.data);
            });
    }

    ngOnInit(): void {
        this.userService.getUserDetails(this.loggedInUser.user_id).subscribe((resultValue: any) => {
          this.userdetailslist = resultValue.data[0];
        });
    }
    redirectTo() {
        this.modalService.dismissAll();
       // this.router.navigateByUrl('/' + UrlConstant.Reports + '/' + UrlConstant.List);
    }
}

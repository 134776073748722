import { AlertMessageType } from './alert-message-types.enum';

export class AlertMessage {

    public timeout: any;

    public id: number = Math.random();

    constructor(public alertType: AlertMessageType, public title: string, public message: string) {
    }
}

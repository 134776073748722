import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConstant } from './constants/app.const';
import { UrlConstant } from './constants/url.const';
import { ErrorComponent } from './core/error-pages/custom-error/custom-error.component';
import { PageNotFoundComponent } from './core/error-pages/page-not-found/page-not-found.component';
import { UnAuthenticatedComponent } from './core/error-pages/unauthenticated/unauthenticated.component';
import { UnAuthorizedComponent } from './core/error-pages/unauthorized/unauthorized.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: UrlConstant.Login,
        pathMatch: 'full'
    },
    {
        path: UrlConstant.Login,
        component: LoginComponent
    },
    {
        path: UrlConstant.Users,
        data: { moduleLevelPermission: AppConstant.Modules.User },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/user/user.module').then(m => m.UserModule),
    },
    {
        path: UrlConstant.Locations,
        data: { moduleLevelPermission: AppConstant.Modules.Location },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/location/location.module').then(m => m.LocationModule),
    },
    {
        path: UrlConstant.Schools,
        data: { moduleLevelPermission: AppConstant.Modules.School },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/school/school.module').then(m => m.SchoolModule),
    },
    {
        path: UrlConstant.Organization,
        data: { moduleLevelPermission: AppConstant.Modules.Organization },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/organization/organization.module').then(m => m.OrganizationModule),
    },
    {
        path: UrlConstant.Students,
        data: { moduleLevelPermission: AppConstant.Modules.Student },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/student/student.module').then(m => m.StudentModule),
    },
    {
        path: UrlConstant.ObservationTypes,
        data: { moduleLevelPermission: AppConstant.Modules.ObservationType },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/observation-type/observation-type.module').then(m => m.ObservationTypeModule),
    },
    {
        path: UrlConstant.Reports,
        data: { moduleLevelPermission: AppConstant.Modules.Report },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/report/report.module').then(m => m.ReportModule),
    },
    {
        path: UrlConstant.Observations,
        data: { moduleLevelPermission: AppConstant.Modules.Observation },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/observation/observation.module').then(m => m.ObservationModule),
    },
    {
        path: UrlConstant.Groups,
        data: { moduleLevelPermission: AppConstant.Modules.Group },
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        loadChildren: () => import('../app/modules/group-names/group-names.module').then(m => m.GroupNamesModule),
    },
    {
        path: UrlConstant.Unauthorized,
        component: UnAuthorizedComponent
    },
    {
        path: UrlConstant.Unauthenticated,
        component: UnAuthenticatedComponent
    },
    {
        path: UrlConstant.ErrorPage,
        component: ErrorComponent
    },
    {
        path: UrlConstant.PageNotFound,
        component: PageNotFoundComponent
    },
    {
        path: UrlConstant.ForgotPassword,
        component: ForgotPasswordComponent
    },
    {
        path: UrlConstant.ResetPassword,
        component: ResetPasswordComponent
    },
    {
        path: '**',
        redirectTo: UrlConstant.PageNotFound,
        pathMatch: 'full'
    }
];

@NgModule({

    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', useHash: true, scrollPositionRestoration: 'enabled', scrollOffset: [0, 0],
            // Enable scrolling to anchors
            anchorScrolling: 'enabled', })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }


export const AppConstant = {

    Roles: {
        L1: 'L1',
        L2: 'L2',
        L3: 'L3',
        L4: 'L4'
    },

    Modules: {
        User: 'User',
        Report: 'Report',
        Location: 'Location',
        Organization: 'Organization',
        ObservationType: 'ObservationType',
        Observation: 'Observation',
        School: 'School',
        Student: 'Student',
        Group: 'Group'
    },

    Pages: {
        AddUser: 'AddUser',
        ViewUser: 'ViewUser',
        EditUser: 'EditUser',
        ListOfUser: 'ListOfUser',

        AddLocation: 'AddLocation',
        ViewLocation: 'ViewLocation',
        EditLocation: 'EditLocation',
        ListOfLocation: 'ListOfLocation',

        AddGroup: 'AddGroup',
        ViewGroup: 'ViewGroup',
        EditGroup: 'EditGroup',
        ListOfGroup: 'ListOfGroup',

        AddOrganization: 'AddOrganization',
        ViewOrganization: 'ViewOrganization',
        EditOrganization: 'EditOrganization',
        ListOfOrganization: 'ListOfOrganization',

        AddObservationType: 'AddObservationType',
        ViewObservationType: 'ViewObservationType',
        EditObservationType: 'EditObservationType',
        ListOfObservationType: 'ListOfObservationType',

        AddSchool: 'AddSchool',
        ViewSchool: 'ViewSchool',
        EditSchool: 'EditSchool',
        ListOfSchool: 'ListOfSchool',

        AddStudent: 'AddStudent',
        ViewStudent: 'ViewStudent',
        EditStudent: 'EditStudent',
        ListOfStudent: 'ListOfStudent',

        ViewReport: 'ViewReport',

        ListOfObservation: 'ListOfObservation',
        ViewObservation: 'ViewObservation'
    },

    ChartType: {
        Bar: 'bar',
        Column: 'column',
        Pie: 'pie',
    }
};

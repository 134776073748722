<div *ngIf="viewForm"></div>
<div *ngIf="editForm"></div>
<div *ngIf="isDetailedView"></div>
<div *ngIf="addForm"></div>

<ng-template [ngIf]="viewForm">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title">
            Follow-Up List
        </h4>
        <div (click)="activeModal.close(true)">Close</div>
    </div>
    <div class="modal-body">
        <form>
            <div class="col lg-12">
                <table class="table observation-list" id="observation-list-grid1">
                    <thead>
                    <tr>
                        <th width="25%">{{ "S.no" }}</th>
                        <th width="25%">{{ "Follow Up Item" | translate }}</th>
                        <th width="25%">{{ "Comments" | translate }}</th>
                        <th width="25%">{{ "Assigned to" | translate }}</th>
                        <th width="25%">{{ "Action Status" | translate }}</th>
                    </tr>
                    </thead>

                    <tbody (click)="isDetailedView = true;viewForm = editForm = false ; ">
                    <tr *ngFor="let item of followUpList; let i = index">
                        <td>{{ i+1 }}</td>
                    <td (click)="followUpDetails(item.id)" >{{ item.followup_item }}</td>
                    <td (click)="followUpDetails(item.id)"  >{{ item.comments }}</td>
                    <td (click)="followUpDetails(item.id)"  >{{ item.assigned_to }}</td>
                    <td (click)="followUpDetails(item.id)"  >{{ item.status == '1' ? 'Completed' : 'In progress'  }}</td>
                    </tr>
                    </tbody>

                    <tbody *ngIf="!followUpList || followUpList.length === 0">
                    <tr>
                        <td colspan="7" style="text-align: center">
                            {{ "Message.NoData" | translate }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-danger" (click)="addForm = true; editForm = viewForm = isDetailedView = false">
            Add New
        </button>
    </div>
</ng-template>

<ng-template [ngIf]="isDetailedView" ]>
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title2">
            Detailed Follow-Up Item
        </h4>
        <div (click)="activeModal.close(true)">Close</div>
    </div>
        <div class="modal-body">
            <form>
                <div class="col p-0 mt-3">
                    <div>
                        <div class="col-lg-8 col-sm-12 col-md-8">

                            <div class="row">
                                <div class="col-lg-6 col-sm-6 col-md-6" style="font-weight: bold">
                                    <p>{{ "Follow Up Item" | translate }}</p>
                                    <p>{{ "Comments"  | translate }}</p>
                                    <p>{{ "Assigned to" | translate }}</p>
                                    <p>{{ "Label.CreatedAt" | translate }}</p>
                                    <p>{{ "Action Status" }}</p>
                                    <p>{{ "Expected date" }}</p>
                                    <p>{{ "Completed date" }}</p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6">
                                    <p>{{ followupitem.followup_item }}</p>
                                    <p>{{ followupitem.comments }}</p>
                                    <p>{{ followupitem.assigned_to }} </p>
                                    <p>{{ followupitem.date_created | datetime}}</p>
                                    <p>{{ followupitem.status == '1' ? 'Completed' : 'In progress' }}</p>
                                    <p>{{ followupitem.expected_date | datetime}}</p>
                                    <p>{{ followupitem.completed_date | datetime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]= followupitem.status
                    class="btn btn-danger" (click)="editForm = true; addForm = viewForm = isDetailedView = false">
                edit
            </button>
        </div>
</ng-template>

<ng-template [ngIf]="editForm">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title4">
            Edit Follow-Up Item
        </h4>
        <div (click)="activeModal.close(true)">Close</div>
    </div>
    <div class="modal-body">
        <form>
            <div class="col p-0 mt-3">
                <div>
                    <div class="col-lg-8 col-sm-12 col-md-8">

                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-md-6" style="font-weight: bold">
                                <p>{{ "Follow Up Item" | translate }}</p>
                                <p>{{ "Comments"  | translate }}</p>
                                <p>{{ "Assigned to" | translate }}</p>
                                <p>{{ "Label.CreatedAt" | translate }}</p>
                                <p>{{ "Action Status" }}</p>
                                <p>{{ "Expected date" }}</p>
                                <p>{{ "Completed date" }}</p>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6">
                                <p>{{ followupitem.followup_item }}</p>
                                <p>{{ followupitem.comments }}</p>
                                <p>{{ followupitem.assigned_to }} </p>
                                <p>{{ followupitem.date_created | date:'shortDate'}}</p>
                                <p><select name="status" id="status">
                                    <option value="Completed" selected="Completed">Completed</option>
                                    <option value="In Progress" selected="In Progress">In Progress</option>
                                </select> </p>
                                <p>{{ followupitem.expected_date | date:'shortDate' }}</p>
                                <p>{{ followupitem.completed_date  | date:'shortDate' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" >
        <button type="submit"
                class="btn btn-danger" (click)="editForm = true; updateFollowItem(followupitem.id); addForm = viewForm = isDetailedView = false">
            Update
        </button>
    </div>
</ng-template>


<ng-template [ngIf]="addForm">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title1">
            Add New Follow-Up List
        </h4>
        <div (click)="activeModal.close(true)">Close</div>
    </div>
    <div class="modal-body">
        <form id="followup-form" #f="ngForm" novalidate (ngSubmit)="f.form.valid && save()" style="display: block;">
            <span *ngIf="f.submitted && f.invalid" > Please enter all mandatory fields </span>
            <div class="col p-0 mt-3">
                <div class="col-lg-8 col-sm-12 col-md-8">

                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6" >
                            <label>Follow Up Title  <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    #followupTypeElement
                                    placeholder="Follow up"
                                    class="form-control"
                                    name="followup_item"
                                    [(ngModel)]="FollowupType.followup_item"
                                    required
                                    #followupType_follow_item="ngModel"
                            />

                            <br>
                            <label>Comments <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    placeholder="comments"
                                    #followupTypeElementComment
                                    class="form-control"
                                    name="comments"
                                    [(ngModel)]="FollowupType.comments"
                                    required
                                    #followupTypecomments="ngModel"
                            />
                            <br>
                            <label>Assigned to  <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    placeholder="Name"
                                    #followupTypeElementassigned
                                    class="form-control"
                                    name="assigned_to"
                                    [(ngModel)]="FollowupType.assigned_to"
                                    required
                                    #followupTypeassigned="ngModel"
                            />
                            <br>
                            <label>Expected date  <span class="required-asterisk">*</span> </label>
                            <input
                                    maxlength="500"
                                    type=date
                                    #followupTypeElementdate
                                    class="form-control"
                                    name="expected_date"
                                    [(ngModel)]="FollowupType.expected_date"
                                    required
                                    [min]="minDate" [max]="maxDate"
                                    #followupTypedate="ngModel"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="submit"
                        class="btn btn-danger">
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>


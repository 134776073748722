import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '../../core/app-injector.service';
import { LocaleService } from '../../core/localization/locale.service';
import { AlertMessageService } from '../services/alert-message.service';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';

export abstract class BaseComponent {

    protected translateService: TranslateService;
    protected localeService: LocaleService;
    protected alertMessageService: AlertMessageService;
    protected authenticationService: AuthenticationService;

    public showLoader: boolean = true;

    public showSearchPanel: boolean = true;

    public flag: any[] = [{ name: 'All', value: 'All' }, { name: 'Active', value: true }, { name: 'InActive', value: false }];

    constructor() {
        const injector = AppInjector.getInjector();
        this.translateService = injector.get(TranslateService);
        this.localeService = injector.get(LocaleService);
        this.alertMessageService = injector.get(AlertMessageService);
        this.authenticationService = injector.get(AuthenticationService);
    }

    public get lang(): string {
        return this.localeService.getCurrentLocale().language.languageCode;
    }

    public setTranslationLocale() {

        const language = this.localeService.getCurrentLocale().language;

        this.translateService.setDefaultLang(language.shortLanguageCode);
        this.translateService.use(language.shortLanguageCode);
    }
}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { RolePermissionMatrix } from '../../configs/role-permission-matrix';
import { Menu } from '../entities/menu';
import { RolePermission } from '../entities/rolePermission';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationService {

    constructor(private authService: AuthenticationService) {
    }

    filterAuthorizedMenuItems(menuItems: Menu[]): Menu[] {

        const filteredMenuItems: Menu[] = [];

        _.forEach(menuItems, item => {

            // Verifying Module Level Permission
            const hasModulePermission = this.hasModuleLevelPermission((<any>item).data.moduleLevelPermission);
            if (hasModulePermission) {

                filteredMenuItems.push(<Menu>{
                    name: item.name,
                    route: item.route,
                    data: item.data,
                    icon: item.icon
                });
            }
        });

        return filteredMenuItems;
    }

    hasModuleLevelPermission(moduleLevelPermission: string): boolean {
        const rolePermission = this.loadRolePermission();
        return rolePermission && rolePermission.moduleLevelPermissions ? _.includes(rolePermission.moduleLevelPermissions, moduleLevelPermission) : false;
    }

    hasPageLevelPermission(pageLevelPermission: string): boolean {
        const rolePermission = this.loadRolePermission();
        return rolePermission && rolePermission.pageLevelPermissions ? _.includes(rolePermission.pageLevelPermissions, pageLevelPermission) : false;
    }

    hasFeatureLevelPermission(featureLevelPermission: string): boolean {
        const rolePermission = this.loadRolePermission();
        return rolePermission && rolePermission.featureLevelPermissions ? _.includes(rolePermission.featureLevelPermissions, featureLevelPermission) : false;
    }

    loadRolePermission(): RolePermission {

        const rolePermission: RolePermission =
            _.find(RolePermissionMatrix, (e: RolePermission) => e.roleName.toLowerCase() === this.authService.loggedInUserInfo.role.toLowerCase());

        return rolePermission;
    }
}

import {AppConstant} from '../constants/app.const';
import {RolePermission} from '../core/entities/rolePermission';

export const RolePermissionMatrix: RolePermission[] =
    [
        {
            roleName: AppConstant.Roles.L1,
            moduleLevelPermissions: [
                AppConstant.Modules.Organization,
                AppConstant.Modules.ObservationType,
                AppConstant.Modules.User
            ],
            pageLevelPermissions: [

                AppConstant.Pages.AddOrganization,
                AppConstant.Pages.ViewOrganization,
                AppConstant.Pages.EditOrganization,
                AppConstant.Pages.ListOfOrganization,

                AppConstant.Pages.AddObservationType,
                AppConstant.Pages.ViewObservationType,
                AppConstant.Pages.EditObservationType,
                AppConstant.Pages.ListOfObservationType,

                AppConstant.Pages.AddUser,
                AppConstant.Pages.ViewUser,
                AppConstant.Pages.EditUser,
                AppConstant.Pages.ListOfUser,
            ],
            featureLevelPermissions: []
        },
        {
            roleName: AppConstant.Roles.L2,
            moduleLevelPermissions: [
                AppConstant.Modules.Report,
                AppConstant.Modules.School,
                AppConstant.Modules.ObservationType,
                AppConstant.Modules.Observation,
                AppConstant.Modules.User
            ],
            pageLevelPermissions: [

                AppConstant.Pages.ViewReport,

                AppConstant.Pages.AddSchool,
                AppConstant.Pages.ViewSchool,
                AppConstant.Pages.EditSchool,
                AppConstant.Pages.ListOfSchool,

                AppConstant.Pages.AddObservationType,
                AppConstant.Pages.ViewObservationType,
                AppConstant.Pages.EditObservationType,
                AppConstant.Pages.ListOfObservationType,

                AppConstant.Pages.ListOfObservation,
                AppConstant.Pages.ViewObservation,

                AppConstant.Pages.AddUser,
                AppConstant.Pages.ViewUser,
                AppConstant.Pages.EditUser,
                AppConstant.Pages.ListOfUser,
            ],
            featureLevelPermissions: []
        },
        {
            roleName: AppConstant.Roles.L3,
            moduleLevelPermissions: [
                AppConstant.Modules.Report,
                AppConstant.Modules.ObservationType,
                AppConstant.Modules.Observation,
                AppConstant.Modules.Location,
                AppConstant.Modules.Student,
                AppConstant.Modules.Group
            ],
            pageLevelPermissions: [

                AppConstant.Pages.ViewReport,

                AppConstant.Pages.AddObservationType,
                AppConstant.Pages.ViewObservationType,
                AppConstant.Pages.EditObservationType,
                AppConstant.Pages.ListOfObservationType,

                AppConstant.Pages.ListOfObservation,
                AppConstant.Pages.ViewObservation,

                AppConstant.Pages.AddLocation,
                AppConstant.Pages.ViewLocation,
                AppConstant.Pages.EditLocation,
                AppConstant.Pages.ListOfLocation,

                AppConstant.Pages.AddGroup,
                AppConstant.Pages.ViewGroup,
                AppConstant.Pages.EditGroup,
                AppConstant.Pages.ListOfGroup,

                AppConstant.Pages.AddStudent,
                AppConstant.Pages.ViewStudent,
                AppConstant.Pages.EditStudent,
                AppConstant.Pages.ListOfStudent,
            ],
            featureLevelPermissions: []
        }
    ];

function isDataEditAccess(data): boolean {
    const loggedInUserData: any = localStorage.getItem('loggedInUserInfo');
    if (loggedInUserData.role === 'L1') {
        return !data.organization_id && !data.branch_organization_id;
    } else if (loggedInUserData.role === 'L2') {
        return loggedInUserData.organization_id === data.organization_id;
    } else if (loggedInUserData.role === 'L3') {
        return loggedInUserData.branch_organization_id === data.branch_organization_id;
    } else {
        return false;
    }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
      webApiUrl: 'https://prodpy.safeschool.us',
    // webApiUrl: 'https://stagepyt.safeschool.us',
    // webApiUrl: 'https://18.191.120.145/api/v1',
    // webApiUrl: 'http://127.0.0.1:5000',
    supportedLanguages: ['en-US'],
    bucketName: 'safeschool-beta'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
